import React, { useEffect, useState } from "react";
import axios from "axios";
import { useStoreState } from "easy-peasy";

import config from "./../../src/configs/config";
import { useHistory } from "react-router-dom";

import DOMPurify from "dompurify";

const SAML = (props) => {
  let history = useHistory();
  const getUserValue = useStoreState((state) => state.user.userInfo);
  const urlParams = new URLSearchParams(window.location.search);
  const SAMLRequest = urlParams.get("SAMLRequest");
  const RelayState = urlParams.get("RelayState");

  const [recievedRequest, setrecievedRequest] = useState(false);
  const [posturl, setUrl] = useState("");
  const [samlRes, setSamlres] = useState("");


  useEffect(() => {
    if (recievedRequest === false && getUserValue !== "") {
      setrecievedRequest(true)
      const URL = config.qa.node_url;
      let apiUrl = `b2rdecrypt`;
      let requestData = {
        SAMLRequest,
        RelayState,
        getUserValue,
      };
      let header = { programcode: "RTL_PNT", token: sessionStorage.accessToken };
      axios
        .post(URL + apiUrl, requestData, {
          headers: header,
        })
        .then((response) => {
          if (response && response.data) {
            const { url, SAMLResponse, RelayState } = response.data;
            debugger
            // var bodyFormData = new FormData();
            // bodyFormData.append('SAMLResponse', SAMLResponse);
            // bodyFormData.append('RelayState', RelayState);
            setUrl(url);
            setSamlres(SAMLResponse);
            if(SAMLResponse){
              document.myForm.submit();
            }
            // axios
            //   .post(url, bodyFormData, {
            //     headers: {
            //       "Content-Type": "multipart/form-data",
            //       'Access-Control-Allow-Origin': '*',
            //       'Access-Control-Allow-Methods': 'POST,OPTIONS',
            //       'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, X-Requested-With'
            //     },
            //   })
            //   .then((response) => {
            //     console.log('ress post', response)
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //   });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // else{
    //   history.push("/errorPage")
    // }
  });



  return (
    <>
    <div className="loader"></div>
    <section className="pnc-help-section d-none">
      <div className="container">Loading.....</div>
      <form id="myForm" name="myForm" action={DOMPurify.sanitize(posturl)} enctype="application/x-www-form-urlencoded" method="post">
        <div>
        <textarea name="SAMLResponse" value={samlRes} />          
        </div>
        <br />
        <div>        
        <textarea name="RelayState" value={RelayState} />     
        </div>       
        <input type="submit" value="Submit!" />
      </form>
    </section>
    </>
  );
};

export default SAML;
