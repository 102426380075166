import React,{useEffect,useState} from 'react';
import {useParams,Link} from "react-router-dom";
import { renderToString } from "react-dom/server";
import axios from 'axios';
import Moment from 'react-moment';
import jsPDF from "jspdf";
import { useStoreState, useStoreActions, Action } from 'easy-peasy';
import { useHistory } from "react-router-dom";
import html2canvas from 'html2canvas';
import Deletemodal from './../modalpopups/deletemodal';
import './../rewardMessage/rewardmessage.css';
import { read_cookie } from "../../read_cookie";
import DOMPurify from "dompurify";

import config from './../../configs/config'
import homeData from './../../data/home.json';

const RenderHtmlElem = (props) => {

    return (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.msgBody) }}></div>
    )
}

const onClickMessage = (e) => { 
    e.preventDefault() 
}

const MessageDetails = (props) =>{
    const getMessagesac = useStoreActions(actions => actions.messages.getMessages);
    const getMessagesValue = useStoreState(state => state.messages.messagesInfo);
    let history = useHistory();
    document.title = props.title;
    let  params  = useParams();
    let [messageDetail,setMessageDetail] = useState([]);
    let [msgBody,setMsgBody] = useState('');
    let [count,setcount] = useState(0);
    let [msgIndex,setmsgIndex] = useState(0);
    const [loading,setloading] = useState(false);
    const [togglePopup,settogglePopup] = useState(false);
    const [msgDate,setmsgDate] = useState('');
    const [sortedGetMessagesValue , setSortedGetMessagesValue] = useState([])
    const pdfGenerate = () => {
        // setloading(true);
        // html2canvas(document.querySelector("#capture")).then(canvas => {
        //     //document.body.appendChild(canvas)
        //     canvas.useCORS = true;
        //     canvas.allowTaint = true;
        //     var myImage = canvas.toDataURL("image/jpeg,1.0");
		// 			// Adjust width and height
        //             var variableCom = window.innerWidth > 768 ? 40 : 20;
		// 			var imgWidth = (canvas.width * variableCom) / 240;
		// 			var imgHeight = (canvas.height * variableCom) / 240; 
		// 			// jspdf changes
		// 			var pdf = new jsPDF('p', 'mm');
		// 			pdf.addImage(myImage, 'JPEG', 15, 15, imgWidth, imgHeight); // 2: 19
        //             pdf.save(`${messageDetail.Subject.split(' ').join('')}.pdf`);
        //             setloading(false);
        // });
        var divContents = document.querySelector("#capture").innerHTML;
        var printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write(DOMPurify.sanitize(DOMPurify.sanitize('<html><head><title>'+messageDetail.Subject.split(' ').join('')+'</title>')));
        printWindow.document.write('</head><body >');
        printWindow.document.write(divContents);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    }

    const goto = (act) => {
        let harId;
        if(act === 'next'){
            harId =msgIndex
        }else{
            harId = msgIndex-2;
        }
        let currHarmonyId = sortedGetMessagesValue[harId].HarmonyMessageId
        history.push(`/messageDetails/harmony/${currHarmonyId}`);
        window.location.reload(false);
        // msgdetail();
        // setcount(0);
    }


    /* PO-213 : Removing delete message functionality.
        const deleteMessages = () => {

        setloading(true);
        const URL = config.qa.node_url;
        let apiUrl = `deleteMessages`;

            let HarmonyMessageId = params.id;
            let requestData = {
                "HarmonyMessageId":[HarmonyMessageId]
              };
            let header={"programcode":"RTL_PNT","token":sessionStorage.accessToken}
              axios.post(URL + apiUrl, requestData, {
                    headers: header
                }).then(function (response) {
                    setloading(false);
                        history.push(`/Messaging`);              
                    })
                    .catch(function (error) {
                        // error;
                        setloading(false);
                    });
        }*/

    const msgdetail = () => {
        setloading(true);

        const URL = config.qa.node_url;

        let ProfileId = read_cookie('profileId');
        let apiUrl = `invoke`;
        let requestData = {
            ProfileId,"HarmonyMessageId":params.id,"Program-Code":"RTL_PNT"
          };
        let header={"script":"RenderEmail","token":sessionStorage.accessToken}
       axios.post(URL + apiUrl, requestData, {
                headers: header
            }).then(function (response) {
                    if(response.data){
                        setMessageDetail(response.data);
                        var bodyHtml = /<body.*?>([\s\S]*)<\/body>/.exec( response.data.Body)[1];
                        setMsgBody(bodyHtml)
                    }     
                    setloading(false);   
                    getMessagesac(sessionStorage.getItem('accessToken'));         
                })
                .catch(function (error) {
                    console.error(error);
                    // error;
                    setloading(false);
                });
                // getMessagesac(sessionStorage.getItem('accessToken'));         
    }
    useEffect(() => {
        msgdetail(); 
      
    }, [])

     if(getMessagesValue && getMessagesValue.length > 1 && count===0){
        let msgInd = 0; 
        var sortedData=getMessagesValue.sort(function(a,b){
        
        return new Date(FormateDate(b.SendDate)) - new Date(FormateDate(a.SendDate));
      })
      setSortedGetMessagesValue(sortedData)
      sortedData.forEach((element,index) => {
            if(element.HarmonyMessageId == params.id){
                msgInd = index+1;
                setmsgDate(FormateDate(element.SendDate));

                // const date = new Date(element.SendDate)
                // const formatedDate=date.toLocaleString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit' , hour: '2-digit', minute: '2-digit', hour12: true }).replace(/,/g,' ');
                // setmsgDate(new Date(element.SendDate))
                // setmsgDate(formatedDate)
                // const dateRegexPattern = /(\d{2}-[A-Z]{3}-\d{2} \d{2}:\d{2}:\d{2}).\d{6} (AM|PM)/g;
                // const dateRegexMatches = dateRegexPattern.exec(element.SendDate);
                // if(dateRegexMatches) {
                //   try {
                //     const date = new Date(dateRegexMatches[1] + ' ' + dateRegexMatches[2]);
                //     const formatedDate = date.toLocaleString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit' , hour: '2-digit', minute: '2-digit', hour12: true }).replace(/,/g,' ');
                //     setmsgDate(formatedDate);
                //   } catch (ex) {
                //     console.log('Invalid date');
                //     setmsgDate('');
                //   }
                // } else {
                //     setmsgDate('');
                // }

                
            }
        }); 
    function FormateDate(d) {
        d = d.replace(/-/g, '/')

        if(d.slice(3,6) == "JAN"){
            d = d.replace("JAN", "01")
        } else if (d.slice(3,6) == "FEB"){
            d = d.replace("FEB", "02")
        } else if (d.slice(3,6) == "MAR"){
            d = d.replace("MAR", "03")
        } else if (d.slice(3,6) == "APR"){
            d = d.replace("APR", "04")
        } else if (d.slice(3,6) == "MAY"){
            d = d.replace("MAY", "05")
        } else if (d.slice(3,6) == "JUN"){
            d = d.replace("JUN", "06")
        } else if (d.slice(3,6) == "JUL"){
            d = d.replace("JUL", "07")
        } else if (d.slice(3,6) == "AUG"){
            d = d.replace("AUG", "08")
        } else if (d.slice(3,6) == "SEP"){
            d = d.replace("SEP", "09")
        } else if (d.slice(3,6) == "OCT"){
            d = d.replace("OCT", "10")
        } else if (d.slice(3,6) == "NOV"){
            d = d.replace("NOV", "11")
        } else if (d.slice(3,6) == "DEC"){
            d = d.replace("DEC", "12")
        }
        const date = d.slice(0,6)+"20"+d.slice(6);
        const formatedDate = date.slice(3,5)+"/"+date.slice(0,2)+date.slice(5,7)+date.slice(7);

        return formatedDate;
    }
        
        let unreadArr = sortedData.filter(msg=>{
            return msg.IsRead === 'N';
        });
        localStorage.unreadMsgCount = unreadArr.length;       
        setmsgIndex(msgInd)
        setcount(1);
       }

    const setPopupState = () => {
        settogglePopup(!togglePopup);  
    }

    return (
        <div className="container">
            {/*  PO-213 : Removing messages delete modal.
            {
                togglePopup ?
                <Deletemodal togglePopup={togglePopup} setPopupState={setPopupState} deleteMessages={deleteMessages}/>
                : false
            } */}
 {
             loading ?
            <div className="loader"></div>
            : false
            }
            
    <div className="wrap-message-detail">
        <div className="row">
            <div className="col-md-12 col-lg-12 pagination-wrap">
                <h3 role="heading" aria-level="1">Message Details</h3>
                <div className="pnc-mD-pagination">
                    {
                        getMessagesValue && getMessagesValue.length > 1   ?
                            <span>
                                {
                                    msgIndex === 1  ?
                                        <a 
                                            href="" 
                                            className="disabled"
                                            onClick={ onClickMessage }>
                                                <img src="/images/circlearrow-left.svg" alt="left-arrow" />
                                        </a>
                                        :
                                        <a 
                                            href="" 
                                            onClick={(e) => { e.preventDefault(); goto() } }>
                                                <img src="/images/circlearrow-left.svg" alt="left-arrow" />
                                        </a>
                                    }
                        
                                    <span className="pagination-numbers">{msgIndex} of {getMessagesValue.length}</span>
                                {
                                msgIndex === getMessagesValue.length  ?
                                    <a 
                                        href="" 
                                        className="disabled"
                                        onClick={ onClickMessage }>
                                            <img src="/images/circlearrow.svg" alt="right-arrow" />
                                    </a>
                                :
                                    <a 
                                        href="" 
                                        onClick={(e) => { e.preventDefault(); goto('next') } }>
                                            <img src="/images/circlearrow.svg" alt="right-arrow" />
                                    </a>
                                }
                        
                            </span>
                        : false 
                    }

                </div>
            </div>
            <div className="col-md-12 col-lg-12">
                <div className="pnc-mD-print float-right">
                    <a 
                        href="" 
                        onClick={(e) => { e.preventDefault(); pdfGenerate() } }>
                            <img className="pnc-print" src="/images/icon-print.svg" alt="print" />
                    </a>
                    {/* PO-213 : Removing delete button from message details view.
                    <a href="javascript:void(0)" onClick={setPopupState}><img src="/images/icon-trash.svg" alt="trash" /></a>
                    */}
                </div>
            </div>
        </div>
        <div className="row pnc-customer-service">
            <div className="col-md-12 col-lg-4">
            <p><strong className="label-from">From:</strong> {homeData.messageSenderTitle}<br />
                {/* {msgDate !== '' ? <Moment format="MM/DD/YYYY hh:mm A">{msgDate}</Moment> :''} */}
                {msgDate !== '' ? (
                    <span>
                        {/* {FormateDate(message.SendDate)} */}
                        <span className="d-none d-lg-inline">
                            <span>
                                {msgDate.slice(0,10)}
                            </span>
                        {" "}
                        </span>
                        <span>
                            {msgDate.slice(11, 16) + " " +msgDate.slice(20, 22)}
                        </span>
                    </span>
                ) :''}    
            </p>
                
            </div>
            <div className="col-md-12 col-lg-7">
                <p>{messageDetail.Subject}</p>
                
            </div>
            <br/><br/>
                        {
                           
                messageDetail.Body ?
                <div className="col-md-12 col-lg-8" id="capture">
            
            <RenderHtmlElem msgBody={msgBody}/>
               {/* <div dangerouslySetInnerHTML={{ __html: msgBody }}> */}
            
            {/* <iframe allowtransparency="true"  srcDoc={messageDetail.Body} width={600} height={window.innerHeight} border="0"/> */}
            </div>
            : false
            }
        </div>
        <Link className="btn cancel pull-right" to="/Messaging" aria-label="Messages">Back to Messages</Link>
        {/* <div className="row desktop-only-rewardtable">
            <div className="col-lg-12">					
                <div className="pnc-mDtable-wrapper">
                            <div className="d-flex pnc-mDtable">
                              <div className="p-3 col-lg-2">Reward Number</div>
                              <div className="p-3 col-lg-3">Rewards Redeemed</div>
                              <div className="p-3 col-lg-1">Qty</div>
                              <div className="p-3 col-lg-1">Points</div>
                              <div className="p-3 col-lg-2">Status</div>
                              <div className="p-3 col-lg-3">In-Store Pickup Number</div>
                            </div>
                            <div className="d-flex mDalternate">
                              <div className="p-3 col-lg-2">6040093258-25</div>
                              <div className="p-3 col-lg-3">Baja Fresh Maxican Grill</div>
                              <div className="p-3 col-lg-1">1</div>
                              <div className="p-3 col-lg-1">10833</div>
                              <div className="p-3 col-lg-2">Complete</div>
                              <div className="p-3 col-lg-3">-</div>
                            </div>
                            <div className="d-flex mDalternate">
                              <div className="p-3 col-lg-2">6040093258-25</div>
                              <div className="p-3 col-lg-3">BP - $25 Gift Card</div>
                              <div className="p-3 col-lg-1">1</div>
                              <div className="p-3 col-lg-1">11222</div>
                              <div className="p-3 col-lg-2">In-Progress</div>
                              <div className="p-3 col-lg-3">-</div>
                            </div>								
                </div>					
            </div>
        </div>
        
        <div className="pnc-message-rewArd">
            <div className="row">
                <div className="col-md-12">
                    <div className="reward-md-wrap">
                    <div className="row m-20">
                        <div className="col-12 col-md-4 m-20">
                            <strong className="label-from">Reward Number: </strong><br />
                            6040088728-25
                        </div>
                        <div className="col-12 col-md-8">
                            <strong className="label-from">Reward Description: </strong> <br />
                            Baja Fresh Maxican Grill - $25 Gift Card
                        </div>
                    </div>
                    <div className="row m-20">
                        <div className="col-12 col-md-4 m-20">
                             <strong className="label-from">Qty: </strong> <br />
                            1
                        </div>
                        <div className="col-12 col-md-8">
                             <strong className="label-from">Points: </strong> <br />
                            10883
                        </div>
                    </div>
                    <div className="row m-20">
                        <div className="col-12 col-md-4 order-mobile-only">
                             <strong className="label-from">Status: </strong> <br />
                            In-Progress
                        </div>
                        <div className="col-12 col-md-8 m-20">
                             <strong className="label-from">In-Store Pickup Number: </strong>  <br />
                            -
                        </div>
                    </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div className="pnc-message-rewArd second-block">
            <div className="row">
                <div className="col-md-12">
                    <div className="reward-md-wrap">
                    <div className="row m-20">
                        <div className="col-12 col-md-4 m-20">
                            <strong className="label-from">Reward Number: </strong><br />
                            6040088728-25
                        </div>
                        <div className="col-12 col-md-8">
                            <strong className="label-from">Reward Description: </strong> <br />
                            Baja Fresh Maxican Grill - $25 Gift Card
                        </div>
                    </div>
                    <div className="row m-20">
                        <div className="col-12 col-md-4 m-20">
                             <strong className="label-from">Qty: </strong> <br />
                            1
                        </div>
                        <div className="col-12 col-md-8">
                             <strong className="label-from">Points: </strong> <br />
                            10883
                        </div>
                    </div>
                    <div className="row m-20">
                        <div className="col-12 col-md-4 order-mobile-only">
                             <strong className="label-from">Status: </strong> <br />
                            In-Progress
                        </div>
                        <div className="col-12 col-md-8 m-20">
                             <strong className="label-from">In-Store Pickup Number: </strong>  <br />
                            -
                        </div>
                    </div>
                    </div>
                    
                </div>
            </div>
        </div> */}
        
        {/* <div className="row Info-about-order">
            <div className="col-md-12 col-lg-3">
                <p className="info-heading"><strong><span className="desktop-break">Information about</span> your order:</strong></p>
            
            </div>
            <div className="col-md-12 col-lg-9">
                <p>1. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis.</p>

                <p>2. Nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in.</p>

                <p>3. Vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet,cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut.</p>

                <p>4. laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
            </div>
        </div>         */}
 </div>	
</div>
    )
}

export default MessageDetails;