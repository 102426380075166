import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import Moment from "react-moment";
import { read_cookie } from "../../read_cookie";
import config from "./../../configs/config";

class expressCard {
  constructor(fname, lname, cardnum) {
    this.firstname = fname;
    this.lastname = lname;
    this.cardno = cardnum;
  }
}

export const LinkedCardLayout = ({ boxData }) => {
  return (
    <div
      className={
        boxData.PrimaryId === boxData.SecondaryId
          ? "d-flex flex-wrap pnc-table-color mAlernate pr-2 activerow"
          : "d-flex flex-wrap pnc-table-color mAlernate pr-2"
      }
    >
      <span className="p-3 col-8 col-md-9 col-lg-3 mdateAlignment">
        {boxData.CardNumber}
      </span>
      <span className="p-3 col-md-2 col-lg-2">
        <Moment format="MM/DD/YYYY">{boxData.CreateDate}</Moment>
      </span>
      <span className="p-3 col-12 col-md-12 col-lg-2">
        {boxData.PrimaryId === boxData.SecondaryId ? "Primary" : "Secondary"}
      </span>
      <span className="p-3 col-md-2 col-lg-3 text-lg-center">
        {boxData.JsonExternalData
          ? boxData.JsonExternalData.AccountType
            ? boxData.JsonExternalData.AccountType
            : ""
          : ""}
      </span>
      <span className="p-3 col-4 col-md-3 col-lg-2 text-lg-right bold-text">
        {boxData.PrimaryId === boxData.SecondaryId ? "Logged In" : ""}
      </span>
    </div>
  );
};

class LinkedCards extends Component {
  constructor(props) {
    super(props);
    document.title = props.title;

    this.state = {
      cardRow: [
        {
          id: 1,
        },
      ],
      submitError: false,
      submitErrorText: "",
      alertType: "danger",
      loading: false,
      linkedCardsArr: [],
      ExpressedlinkHistory:[],
      maxCards:4,
      cardsLinkedToday:0,
      showMaxErrorMessage: false,
    };
  }

  componentDidMount() {
    let $this = this;
    $this.setState({
      loading: true,
    });

    const URL = config.qa.node_url;

    let ProfileId = this.cleanProfileId(read_cookie("profileId"));
    let apiUrl = `linkcardspool`;
    let requestData = {
      ProfileId,
    };
    let header = { programcode: "RTL_PNT", token: read_cookie("pnc-rewards-token") };
    // console.log(header);
    axios
      .post(URL + apiUrl, requestData, {
        headers: header,
      })
      .then(function (response) {
        let res = response.data;
        let linkedCardsArr = [];
        if (res && res.length > 0) {
          let sec = res.filter((el) => {
            return el.PrimaryId !== el.SecondaryId;
          });
          let prim = res.filter((el) => {
            return el.PrimaryId === el.SecondaryId;
          });
          linkedCardsArr = prim.concat(sec);
        }
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 200);
        $this.setState({
          loading: false,
          linkedCardsArr,
        });
      })
      .catch(function (error) {
        console.error(error);
        // error;
        $this.setState({
          loading: false,
        });
      });
      this.getLinkHistory();
  }

  cleanProfileId(profileId) {
    let isClean = /^[A-Za-z0-9-]*$/.test(profileId),
        cleanId;
    
    // console.log(isClean)
    // console.log(profileId)
    if(!isClean) {
      cleanId = profileId.replace(/[^0-9a-z-]/gi, '');
      // console.log('cleaned', cleanId);
      return cleanId;
    }
    else {
      return profileId;
    }
  }

  addRow = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      if (prevState.cardRow.length <= this.state.maxCards) {
        prevState.cardRow.push({ id: prevState.length + 1 });
      }
      return prevState;
    });
  };

  removeRow = (data) => (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      if (prevState.cardRow.length > 1) {
        prevState.cardRow.splice(data.id - 1, 1);
      }
      return prevState;
    });
  };

  validateForm = () => {
    var parent = this;
    let cardArr = [];
    $(".cf-wrapper").each(function () {
      var currRow = {};
      var errorArr = [];
      $(this)
        .find("input")
        .each(function () {
          let currVal = $(this).val();
          if (!currVal || currVal.length === 0) {
            errorArr.push($(this).attr("data-key"));
          } else {
            var key = $(this).attr("name");
            currRow[key] = currVal;
          }
        });

      if (errorArr.length > 0) {
        $(this)
          .find(".error")
          .text("Please enter " + errorArr);
      } else {
        $(this).find(".error").text("");
        cardArr.push(currRow);
      }
    });
    $(".cf-wrapper input").blur(function () {
      parent.validateForm();
    });

    return cardArr;
  };

  submitForm = (e) => {
    e.preventDefault();
    let finalCardArr = this.validateForm();
    let rowsCount = $(".cf-wrapper").length;
    if (finalCardArr.length > 0 && rowsCount === finalCardArr.length) {
      this.setState({
        loading: true,
      });
      let cardLinkArr = finalCardArr.map((linkcard) => {
        let linkcardObj = new expressCard(
          linkcard.fname,
          linkcard.lname,
          linkcard.cardNum
        );
        return linkcardObj;
      });
      // console.log(cardLinkArr);
      let apiUrl = `linkcards`;

      let requestData = {
        PRIMARY_PROFILE_ID: read_cookie("profileId"),
        CardInfo: cardLinkArr,
        CHANGE_METHOD_CODE: "WEB",
        LINK_REQUEST_STATUS: "P",
        STATUS: "A",
        REQUEST_TYPE: "L",
        REASON: "YES",
        REQUEST_STATUS: "PENDING ISSUER ACTION",
      };
      const URL = config.qa.node_url;
      let header = {
        programcode: "RTL_PNT",
        token: sessionStorage.accessToken,
      };
      let $this = this;
      axios
        .post(URL + apiUrl, requestData, {
          headers: header,
        })
        .then(function (response) {
          // console.log(response);
          if (response.data.ErrorCode) {
            $this.setState({
              submitErrorText:
                "The request you entered cannot be completed, please double check the information provided. If you need assistance please contact PNC Customer Care at 855-PNC-RWDS (855-762-7937)",
              submitError: true,
              alertType: "danger",
              loading: false,
            });
            setTimeout(function () {
              $this.setState({
                submitErrorText: "",
                submitError: false,
              });
            }, 10000);
          } else {
            $this.setState({
              submitErrorText: "Express Linking Successful",
              submitError: true,
              alertType: "success",
              loading: false,
            });
            setTimeout(function () {
              $this.setState({
                submitErrorText: "",
                submitError: false,
              });
              window.location.reload(false);
            }, 10000);
          }
          return response;
        })
        .catch(function (error) {
          console.error(error);
          // setloading(false);
          $this.setState({
            loading: false,
          });
          return error;
        });
    }
  };

  showMaxError = () => {
    this.setState({
      showMaxErrorMessage:true
    })
  }

  getLinkHistory = () => {
   let  $this = this;
    const URL = config.qa.node_url;

    let ProfileId = read_cookie('profileId');
    let apiUrl = `invoke`;
    let requestData = {
        ProfileId,"Program-Code":"RTL_PNT"
      };
    let header={"script":"GetExpressInfoHistoryById","token":read_cookie("pnc-rewards-token")}
      axios.post(URL + apiUrl, requestData, {
            headers: header
        }).then(function (response) {
                if(response.data){
                  const {ExpressedlinkHistory} = response.data;
                  let filterdExpressedlinkHistory = ExpressedlinkHistory.filter(el=>{
                    return (el.REQUEST_TYPE === "L" &&  el.LINK_REQUEST_STATUS === "P" && el.STATUS ==="A")
                  })
                  const oneDay = 24 * 60 * 60 * 1000;
                  let now = new Date();
                  let maxCardsForDayArr = filterdExpressedlinkHistory.filter(el=>{                   
                    var createdAt = new Date(el.CREATE_DATE);
                    return (now - createdAt) < oneDay
                  });
                  
                  let maxCardsForDay = 4 - maxCardsForDayArr.length;
                  $this.setState({
                    ExpressedlinkHistory:filterdExpressedlinkHistory,
                    maxCards:maxCardsForDay,
                    cardsLinkedToday:maxCardsForDayArr.length
                  });
                }   
                     
            })
            .catch(function (error) {
                console.error(error);
             
            });
}

  render() {
    const { cardRow,ExpressedlinkHistory } = this.state;

    return (
      <section>
        <div className="container">
          {this.state.loading ? <div className="loader"></div> : false}
          <div className="row">
            <div className="offset-md-1 col-md-10">
              <div className="wrap-acc">
                <h3 role="heading" aria-level="1">
                  My Linked Cards
                </h3>
                <p className="pnc-sub-heading">
                  Link Your Cards to Make Your PNC points Account Even More
                  Rewarding.
                </p>
                <p>
                  Linking is a way to bring multiple PNC cards together in a
                  single point-earning account, giving you a way to earn rewards
                  faster.
                </p>
                <p>
                  One enrolled card becomes the "primary" card and other linked
                  Visa cards become "secondary" cards. Every purchase made on
                  each card earns points in the primary card's account. If a
                  Credit Card account participating in the points program is
                  closed, all of the unused points associated with that Credit
                  Card account number will be forfeited. This also applies to
                  linked cards.
                </p>
                {/* <p>
                  The card you are logged in with is not currently in a linked
                  relationship.
                </p> */}

                {this.state.linkedCardsArr.length > 0 ? (
                  <div className="row mb-4">
                    <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12">
                      <p className="pnc-sub-heading">
                        Current Linked Cards{" "}
                        <a
                          href="javascript:void(0)"
                          data-toggle="tooltip"
                          data-placement="right"
                          data-html="true"
                          data-original-title="<b>Linked Cards</b><br/>These cards can earn points to contribute to this PNC points account"
                        >
                          <img
                            src="/images/tootip.svg"
                            className="pnc-tooltip"
                            alt="tootltip"
                          />
                        </a>
                      </p>
                      <div className="pnc-flex-wrapper-order">
                        <div className="d-flex pnc-table-color-order pr-2">
                          <div className="p-3 col-lg-3">Card Ending In</div>
                          <div className="p-3 col-lg-2">Date Linked</div>
                          <div className="p-3 col-lg-2">Role</div>
                          <div className="p-3 col-lg-3 text-lg-center">
                            Account Type
                          </div>
                          <div className="p-3 col-lg-2 text-lg-right"></div>
                        </div>

                        {this.state.linkedCardsArr.map((value, index) => {
                          return (
                            <LinkedCardLayout
                              boxData={value}
                              key={index}
                            ></LinkedCardLayout>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  /* <p>
                    The request you entered cannot be completed, please double
                    check the information provided. If you need assistance
                    please contact PNC Customer Care at 855-PNC-RWDS
                    (855-762-7937).
                  </p> */
                  ""
                )}

                <p className="pnc-sub-heading">Request to Link Cards</p>
                <p>
                  Enter the information for the card(s) you want linked in the
                  boxes below. The information must be entered as it appears on
                  the card. You can enter up to five cards per day. Please note,
                  once you have made a request to link cards, you must wait 24
                  hours before submitting another request to link additional
                  cards or a request to unlink cards.
                </p>
                {
                  ExpressedlinkHistory.length > 0 ?
                  <div className="ExpressedlinkHistory clearfix">
                  {
                    ExpressedlinkHistory.map((el,index) => {
                      return (<div key={index} className="">
                        <input type="text" placeholder={`${el.LAST_DIGITS} - Pending...`} disabled className="cf-input half"/>
                        {/* <p>Processing</p> */}
                      </div>)
                    })
                  }
                  </div>                  

                    :
                    false
                }
                {
                  this.state.maxCards <= -1 ?
                  <>
                  { this.state.showMaxErrorMessage ?
                    <div
                      className="alert alert-danger"
                      role="alert"
                    >
The request you entered cannot be completed, please double check the information provided. If you need assistance please contact PNC Customer Care at 855-PNC-RWDS (855-762-7937)
                    </div> : false
                  }
                    <button onClick={this.showMaxError} className="btn btn-blue">
                        Request Linking
                      </button>
                    </>
                    :

                    <form className="card-form" onSubmit={this.submitForm}>
                      <div className="mb-30">
                        {cardRow &&
                          cardRow.map((card, index) => {
                            return (
                              <CardFields
                                key={index}
                                index={index + 1 + this.state.cardsLinkedToday}
                                showPlus={
                                  cardRow.length <= this.state.maxCards
                                    ? cardRow.length === index + 1
                                    : false
                                }
                                showMinus={cardRow.length === 1 ? false : true}
                                addRow={this.addRow}
                                removeRow={this.removeRow}
                                {...card}
                              />
                            );
                          })}
                      </div>
                      {this.state.submitError ? (
                        <div
                          className={`alert alert-${this.state.alertType}`}
                          role="alert"
                        >
                          {this.state.submitErrorText}
                        </div>
                      ) : (
                        false
                      )}

                      <button type="submit" className="btn btn-blue">
                        Request Linking
                      </button>
                    </form>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export const CardFields = (props) => {
  return (
    <div className="cf-wrapper clearfix">
      <label className="cf-label">
        {`Card ${props.index}`}{" "}
        <span className="icon-wrapper">
          {props.showPlus && (
            <a href="#" onClick={props.addRow}>
              <i className="icon-add"></i>
            </a>
          )}{" "}
          {props.showMinus && (
            <a href="#" onClick={props.removeRow(props)}>
              <i className="icon-remove"></i>
            </a>
          )}
        </span>
      </label>

      <input
        type="text"
        data-key=" First Name"
        name="fname"
        placeholder="First Name"
        className="cf-input quarter"
      ></input>

      <input
        type="text"
        data-key=" Last Name"
        name="lname"
        placeholder="Last Name"
        className="cf-input quarter"
      ></input>

      <input
        type="text"
        data-key=" Card Number"
        name="cardNum"
        placeholder="Card Number (Last Four digit:XXXX)"
        className="cf-input half"
        maxLength="4"
        minLength="4"
      ></input>
      <span className="error"></span>
    </div>
  );
};
export default LinkedCards;
