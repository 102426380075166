import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from "react-moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import $ from "jquery";
import Deletemodal from "../modalpopups/deletemodal";
import Pagination from "react-js-pagination";
import { inclusiveRange } from "./../transaction/transaction";
import "./rewardmessage.css";
import { read_cookie } from "../../read_cookie";
import config from "./../../configs/config";
import homeData from './../../data/home.json';

// Set the timezone for every instance.
Moment.globalTimezone = "America/Chicago";

const RewardMessages = (props) => {
  document.title = props.title;

  let [messages, setMessages] = useState([]);
  let [messagesidArr, setMessagesidArr] = useState([]);
  const [togglePopup, settogglePopup] = useState(false);
  const [loading, setloading] = useState(false);
  let [records, setRecords] = useState(10);
  let [recordsArr, setRecordsArr] = useState([10]);
  let [sort, setsort] = useState("down");
  let [activePage, setactivePage] = useState(1);
  let [startCount, setStartCount] = useState(0);

  const handlePageChange = (pagenum) => {
    setactivePage(pagenum);
    let stCount = pagenum * records - records;
    // let endCount = (pagenum * records)+1;
    // console.log(stCount,records,trans.slice(stCount,(stCount+records)),(stCount+records))
    setStartCount(stCount);
    $(".form-check-input").prop("checked", false);
    checkboxChange();
  };
  const FormateDate = (d) => {
    d = d.replace(/-/g, '/')

    if(d.slice(3,6) == "JAN"){
        d = d.replace("JAN", "01")
    } else if (d.slice(3,6) == "FEB"){
        d = d.replace("FEB", "02")
    } else if (d.slice(3,6) == "MAR"){
        d = d.replace("MAR", "03")
    } else if (d.slice(3,6) == "APR"){
        d = d.replace("APR", "04")
    } else if (d.slice(3,6) == "MAY"){
        d = d.replace("MAY", "05")
    } else if (d.slice(3,6) == "JUN"){
        d = d.replace("JUN", "06")
    } else if (d.slice(3,6) == "JUL"){
        d = d.replace("JUL", "07")
    } else if (d.slice(3,6) == "AUG"){
        d = d.replace("AUG", "08")
    } else if (d.slice(3,6) == "SEP"){
        d = d.replace("SEP", "09")
    } else if (d.slice(3,6) == "OCT"){
        d = d.replace("OCT", "10")
    } else if (d.slice(3,6) == "NOV"){
        d = d.replace("NOV", "11")
    } else if (d.slice(3,6) == "DEC"){
        d = d.replace("DEC", "12")
    }
    const date = d.slice(0,6)+"20"+d.slice(6);
    const formatedDate = date.slice(3,5)+"/"+date.slice(0,2)+date.slice(5,7)+date.slice(7);
    // const date = new Date(d)
    // const formatedDate=date.toLocaleString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit'  });
    // const formatedTime=date.toLocaleString('en-US',{hour: '2-digit', minute: '2-digit', hour12: true });
    // const CompleteDate=formatedTime+" "+formatedDate
    return formatedDate;
    // const dateRegexPattern = /(\d{2}-[A-Z]{3}-\d{2} \d{2}:\d{2}:\d{2}).\d{6} (AM|PM)/g;
    // const dateRegexMatches = dateRegexPattern.exec(d);
    // if(dateRegexMatches) {
    //   try {
    //     const date = new Date(dateRegexMatches[1] + ' ' + dateRegexMatches[2]);
    //     const formatedDate=date.toLocaleString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit'  });
    //     const formatedTime=date.toLocaleString('en-US',{hour: '2-digit', minute: '2-digit', hour12: true });
    //     const CompleteDate=formatedTime+" "+formatedDate
    //     return CompleteDate;
    //   } catch (ex) {
    //     console.log('Invalid date');
    //     return '';
    //   }
    // } else {
    //   return '';
    // }
  }
  const sortData = (des) => {
    if (!des) {
      if (sort === "down") {
        let sortedval = messages.sort(
          (a, b) => new Date(FormateDate(a.SendDate)) - new Date(FormateDate(b.SendDate))
        );
        setsort("up");
      } else {
        let sortedval = messages.sort(
          (a, b) => new Date(FormateDate(b.SendDate)) - new Date(FormateDate(a.SendDate))
        );
        setsort("down");
      }
    } else {
      let sortedval = messages.sort(
        (a, b) => new Date(FormateDate(b.SendDate)) - new Date(FormateDate(a.SendDate))
      );
      setsort("down");
    }
  };

  const updateRecords = (e) => {
    setRecords(e.target.value);
    handlePageChange(1);
  };
  const checkboxChange = () => {
    let deleteArr = [];
    let checks = $(".mdateAlignment .form-check-input");
    checks.each(function () {
      if ($(this).is(":checked")) {
        let delId = $(this).attr("data-id");
        deleteArr.push(delId);
      }
    });
    setMessagesidArr(deleteArr);
    // PO-213 : Removing handling of message delete button
    if (deleteArr.length > 0) {
      //$(".pnc-delete").show();
      $(".pnc-message-count-num").text(`(${deleteArr.length} Selected)`);
    } else {
      //$(".pnc-delete").hide();
      $(".pnc-message-count-num").text(`(0 Selected)`);
    }
    if (deleteArr.length === $(".form-check-input").not(".selectall").length) {
      $(".selectall").prop("checked", true);
    } else {
      $(".selectall").prop("checked", false);
    }
  };

  const renderMessages = () => {
    setloading(true);

    const URL = config.qa.node_url;

    let ProfileId = read_cookie("profileId");
    let apiUrl = `getProfileEmailMessages`;
    let requestData = {
      ProfileId,
    };
    let header = { programcode: "RTL_PNT", token: sessionStorage.accessToken };
    axios
      .post(URL + apiUrl, requestData, {
        headers: header,
      })
      .then(function (response) {
        if (response.data) {
          setloading(false);
          let msgData = [...response.data];
          msgData.sort((a, b) => new Date(FormateDate(b.SendDate)) - new Date(FormateDate(a.SendDate)));
          setMessages(msgData);
          let numberOfRecords = response.data.length > 0 ? response.data.length : 1;
          setRecordsArr(inclusiveRange(10, numberOfRecords, 10));
          $(".selectall").prop("checked", false);
          checkboxChange();
        }
      })
      .catch(function (error) {
        console.error(error);
        setloading(false);
        // error;
      });
  };

  useEffect(() => {
    renderMessages();
    $(".selectall").click(function () {
      // PO-213 : Removing handling of message delete button
      if ($(this).is(":checked")) {
        $("input:checkbox").prop("checked", true);
        //$(".pnc-delete").show();
      } else {
        $("input:checkbox").prop("checked", false);
        //$(".pnc-delete").hide();
      }
      checkboxChange();
    });
  }, []);

  const setPopupState = () => {
    settogglePopup(!togglePopup);
    // setMessagesidArr([...messagesidArr]);
    // if(id){
    //     setMessagesidArr([id])
    // }else{
    //     setMessagesidArr([])
    // }
  };

  /* PO-213 : Removing delete message functionality.
  const deleteMessages = () => {
    const URL = config.qa.node_url;
    let apiUrl = `deleteMessages`;
    console.log(messagesidArr);
    // messagesidArr.forEach(msgid => {
    // let HarmonyMessageId = msgid;
    let requestData = {
      HarmonyMessageId: messagesidArr,
    };
    let header = { programcode: "RTL_PNT", token: sessionStorage.accessToken };

    axios
      .post(URL + apiUrl, requestData, {
        headers: header,
      })
      .then(function (response) {
        if (response.data) {
          console.log(response.data);
          $(".selectall,.form-check-input").prop("checked", false);
        }
        renderMessages();
        setPopupState();
      })
      .catch(function (error) {
        console.error(error);
        // error;
        renderMessages();
        setPopupState();
      });
    // })
  }; */

  return (
    <div className="container">
      {/*  PO-213 : Removing messages delete modal.
      {togglePopup ? (
        <Deletemodal
          togglePopup={togglePopup}
          setPopupState={setPopupState}
          deleteMessages={deleteMessages}
        />
      ) : (
        false
      )} */}
      {loading ? <div className="loader"></div> : false}
      <div className="wrap-reward-messages">
        <div className="row">
          <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12">
            <div className="rewards-text">
              <h3 role="heading" aria-level="1">
                PNC Points | Messages
              </h3>
            </div>
            {/* <div className="rewards-sort text-right d-none d-lg-none d-md-block d-block">
              <span className="col-4 sort">
                <a className="sort-inactive" href="#">
                  <img
                    className="sort-img"
                    src="/images/icon-sort.svg"
                    alt="sort"
                  />
                  Sort
                </a>
                <a className="sort-expand" href="#">
                  <img
                    className="close-img"
                    src="/images/close-button.svg"
                    alt="close"
                  />{" "}
                  Close
                </a>
              </span>
            </div> */}
          </div>
        </div>
        <div className="row pnc-reward-block-wrap">
          <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12">
            {/* <div className="pnc-sort-mb">
              <p>SORT BY</p>
              <select
                id="filter-mb"
                className="form-control selectBox"
                aria-label="list-box"
                role="listbox"
              >
                <option value="10">Date</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <button type="submit" className="btn apply-filter">
                Apply Filter
              </button>
            </div> */}
            <div className="clearfix">
              <div className="inline-block float-left">
                <span className="all-select d-lg-none">
                  <a href="#">All</a>
                </span>
                <span className="message-count">
                  {messages.length} Messages
                </span>
                <span className="pnc-message-count-num"> </span>
              </div>

              {/* PO-213 : Removing delete button from messages listing.
                <span className="float-right pnc-delete" onClick={setPopupState}>
                <img src="/images/icon-trash.svg" alt="trash" />
                Delete
                </span>*/}
              <div className=" pagination-wrap">
              <div className="float-right view-per-page-sec">
                <div className="d-flex view-per-page-sec-inner">
                  <span>View</span>

                  <select
                    id="filter"
                    className="form-control selectBox"
                    onChange={updateRecords}
                    role="listbox"
                    aria-label="selectbox-pagination"
                  >
                    {recordsArr.slice(0,10).map((rec, index) => {
                      return (
                        <option value={rec} key={index}>
                          {rec}
                        </option>
                      );
                    })}
                  </select>
                  <span>Per page</span>
                </div>
              </div></div>
            </div>
            <div className="pnc-reward-wrapper">
              <div className="d-flex pnc-reward-message-table">
                {messages ? (
                  <div className="p-3 col-lg-4 check-box">
                    <div className="form-check d-none d-lg-block">
                      <input
                        type="checkbox"
                        className="form-check-input selectall"
                        id="electronic-program1"
                        value=""
                      />
                      <label
                        className="reward-message"
                        htmlFor="electronic-program1"
                      >
                        <span className="d-none">checkbox</span>
                      </label>
                    </div>
                    Sender
                  </div>
                ) : (
                  false
                )}
                <div className="p-3 col-lg-5">Subject </div>
                <div
                  className="p-3 col-lg-3"
                  onClick={() => {
                    sortData();
                  }}
                >
                  Time/Date{" "}
                  {sort === "up" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
                {/* <div className="p-3 col-lg-1"></div> */}
              </div>
              {messages && messages.length > 0 ? (
                messages
                  .slice(startCount, startCount + records)
                  .map((message, index) => {
                    let isRead = message.IsRead === "Y" ? "" : "unread-message";
                    let type =
                      message.IsCampaignMessage === "true"
                        ? "campaign"
                        : "harmony";
                    let id =
                      message.IsCampaignMessage === "true"
                        ? message.CampaignMessageId
                        : message.HarmonyMessageId;
                    return (
                      <div
                        key={index}
                        className={`d-flex flex-wrap mAlernate ${isRead}`}
                        // href=""
                      >
                        <div className="p-3 col-6 col-md-4 col-lg-4 mdateAlignment">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              data-id={id}
                              id={"chk" + index}
                              onChange={checkboxChange}
                            />
                            <label
                              className="reward-message"
                              htmlFor={"chk" + index}
                            >
                              <span className="d-none">checkbox</span>
                            </label>
                          </div>

                          <Link to={`messageDetails/${type}/${id}`}>
                            {homeData.messageSenderTitle}
                          </Link>
                        </div>
                        <Link
                          to={`messageDetails/${type}/${id}`}
                          className="p-3 col-12 col-md-12 col-lg-5"
                        >
                          <span> {message.Description}</span>
                        </Link>
                        <Link
                          to={`messageDetails/${type}/${id}`}
                          className="p-3 col-6 col-md-8 col-lg-3 mcustomerService"
                        >
                          <span>
                            {/* {FormateDate(message.SendDate)} */}
                            <span className="d-none d-lg-inline">
                              <span>
                                {FormateDate(message.SendDate).slice(11, 16) + " " +FormateDate(message.SendDate).slice(20, 22)}
                              </span>
                              {" "}
                            </span>
                            <span>
                              {FormateDate(message.SendDate).slice(0,10)}
                            </span>
                          </span>
                        </Link>

                        {/* <span className="p-3 col-6 col-md-8 col-lg-1 mcustomerService">
                                            <span className="text-center delicon" onClick={()=>setPopupState(id)}>
                            <img src="/images/icon-trash.svg" alt="trash" />
                        </span>   
                                            </span> */}
                      </div>
                    );
                  })
              ) : (
                <a className="d-flex flex-wrap mAlernate" href="#">
                  <span className="p-3 col-6 col-md-4 col-lg-3 mdateAlignment">
                    {/* <div className="form-check">
                                          <input type="checkbox" className="form-check-input" id="electronic-program2" value="" />
                                          <label className="reward-message" htmlFor="electronic-program2"><span className="d-none">checkbox</span></label>
                                      </div> */}
                    {!loading ? "NO Records Found" : ""}
                  </span>
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="float-right view-per-page-sec">
          <div className="d-flex view-per-page-sec-inner">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={records}
              totalItemsCount={messages.length}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              hideDisabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardMessages;
